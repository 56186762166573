import "../styles/globals.css";
import type { AppProps } from "next/app";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import { nhost } from "../utils/nhost";
import Head from "next/head";
import { NhostApolloProvider } from "@nhost/react-apollo";
import { NhostProvider } from "@nhost/nextjs";

const theme = extendTheme({
  components: {
    FormLabel: {
      baseStyle: {
        marginBottom: 1,
      },
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  const isMaintenance = process.env.NEXT_PUBLIC_MAINTENANCE === "true";

  return (
    <>
      <Head>
        <title>SEEKER</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Head>
      <ChakraProvider theme={theme}>
        <NhostProvider nhost={nhost} initial={pageProps.nhostSession}>
          <NhostApolloProvider nhost={nhost}>
            {isMaintenance && (
              <Alert status="warning" justifyContent={`center`}>
                <AlertIcon />
                <AlertTitle>Probíhá údržba!</AlertTitle>
                <AlertDescription>
                  Prosím nevytvářejte nové záznamy, neboť mohou být smazány.
                </AlertDescription>
              </Alert>
            )}
            <Component {...pageProps} />
          </NhostApolloProvider>
        </NhostProvider>
      </ChakraProvider>
    </>
  );
}

export default MyApp;
